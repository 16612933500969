import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderService } from '@app/core/services';
import { AsyncPipe } from '@angular/common';
import { LoaderComponent } from '@app/shared/components/loader/loader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AsyncPipe, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Voucherry-CPL-FrontEnd';
  constructor(
    public loaderService: LoaderService
  ) {
  }
}
